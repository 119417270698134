import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ucenter',
    redirect:'/login'
   
  },
  {
    path: '/xieyi',
    name: 'ucenter',
    component: () => import('../views/xieyi/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
    meta: {
      name: '登录'
    }
  },
  {
    path: '/collectlogin',
    name: 'Login',
    component: () => import('../views/login/collectlogin.vue'),
    meta: {
      name: '登录'
    }
  },
  {
    path: '/bindtelphone',
    name: 'Bindtelphone',
    component: () => import('../views/login/bindtelphone.vue'),
    meta: {
      name: '绑定手机号'
    }
  },
  {
    path: '/scanloading',
    name: 'scanloading',
    component: () => import('../views/login/scanloading.vue'),
    meta: {
      name: '扫码登录'
    }
  },
  {
    path: '/bindmemberinfo',
    name: 'bindmemberinfo',
    component: () => import('../views/login/bindmemberinfo.vue'),
    meta: {
      name: '注册信息'
    }
  },
  {
    path: '/member',
    name: 'Member',
    redirect: '/member/info',
    component: () => import('../views/member/member.vue'),
    meta: {
      name: '会员信息'
    },
    children: [
      {
        path: '/member/buyvip',
        name: 'buyvip',
        component: () => import('../views/member/buyvip.vue'),
        meta: {
          name: '土拍会员'
        }
      },
      {
        path: '/member/info',
        name: 'MemberInfo',
        component: () => import('../views/member/info.vue'),
        meta: {
          name: '个人信息'
        }
      },
      {
        path: '/member/byrecord',
        name: 'Setpassword',
        component: () => import('../views/member/byrecord.vue'),
        meta: {
          name: '购买记录'
        }
      },
      {
        path: '/member/changetel',
        name: 'changetel',
        component: () => import('../views/member/changetel.vue'),
        meta: {
          name: '换绑手机'
        }
      },
      {
        path: '/member/setpwd',
        name: 'setpwd',
        component: () => import('../views/member/setpwd.vue'),
        meta: {
          name: '修改密码'
        }
      }

    ]

  }

]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

//到处econ的经济路由
//  const econ = routes[0].children
export {
  routes
}